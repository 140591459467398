<template>
  <div>
    <!-- <b-overlay center :show="isLoading" rounded="sm">
      <div v-show="!isLoading"> -->
    <form-wizard
      ref="wizard"
      :startIndex="startIndex"
      @on-complete="onComplete"
      :title="title"
      :subtitle="subtitle"
      :color="color"
      :validateOnBack="true"
      @finish="onFinish"
    >
      <!-- nextButtonText="Avanti"
          finishButtonText="Finisci" -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <!-- remove previous button -->
          <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
        </div>
        <div
          :class="`wizard-footer-right${props.isLastStep ? ' hidden' : ''}`"
          :style="getWidth"
        >
          <!-- #842 nascondere il tasto 'next' sullo step4-->
          <wizard-button
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            :disabled="isLoading"
            >{{
              props.isLastStep
                ? finished
                  ? "Completato"
                  : "Finisci"
                : "Avanti"
            }}</wizard-button
          >
        </div>
      </template>
      <template slot="step" slot-scope="props">
        <wizard-step
          :tab="props.tab"
          :transition="props.transition"
          :index="props.index"
        >
        </wizard-step>
      </template>

      <tab-content
        v-for="tab in tbs"
        :key="tab.title"
        :title="tab.title"
        :icon="tab.icon"
        :lazy="tab.lazy"
        :before-change="() => validateStep(tab.component, tab.index)"
      >
        <b-overlay center :show="isLoading" rounded="sm">
          <div v-show="!isLoading">
            <!-- $refs.wizard.activeTabIndex -->
            <!-- <b-card
              :header="'Step #' + (tab.index + 1)"
              header-tag="header"
              class="mt-2"
            > -->
            <h5>Step #{{ tab.index + 1 }}</h5>
            <component
              v-if="!tab.hide"
              :ref="tab.component"
              :is="tab.component"
              :resourceId="resourceId"
              :extras="extras"
              @fetch="$emit('fetch')"
              @validate="validateStep"
            ></component>
            <hr />
            <!-- </b-card> -->
          </div>
          <template #overlay>
            <div class="text-center">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">Operazione in corso...</p>
            </div>
          </template>
        </b-overlay>
        <!-- @on-validate="mergeTabModel" -->
      </tab-content>
    </form-wizard>
    <!-- </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay> -->
  </div>
</template>
<script>
// //local registration
// import { FormWizard, TabContent } from "vue-form-wizard";
// import "vue-form-wizard/dist/vue-form-wizard.min.css";

import BaseIcon from "@/components/BaseIcon";
import Step1 from "@/components/communication/procedures/steps/step1";
import Step2 from "@/components/communication/procedures/steps/step2";
import Step3 from "@/components/communication/procedures/steps/step3";
import Step4 from "@/components/communication/procedures/steps/step4";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      isLoading: false,
      finished: false,
      buttonWidth: null,
    };
  },
  components: {
    // FormWizard,
    // TabContent,
    BaseIcon,
    Step1,
    Step2,
    Step3,
    Step4,
    // Step1: () => import("@/components/communication/procedures/steps/step1"),
    // Step2: () => import("@/components/communication/procedures/steps/step2"),
    // Step3: () => import("@/components/communication/procedures/steps/step3"),
    // Step4: () => import("@/components/communication/procedures/steps/step4"),
  },
  props: {
    repository: String,
    resourceId: Number,
    title: String,
    subtitle: String,
    color: String,
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    startIndex: {
      type: Number,
      default: 0,
    },
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      console.log("resize Wizard", this.$refs.wizard?.$el.clientWidth);
      // this.buttonWidth = null;
      // this.buttonWidth = `width: calc(${this.$refs.wizard?.$el.clientWidth}px + 2rem);`;
      this.buttonWidth = `width: ${
        document.querySelector(".dashboard").clientWidth
      }px;`;
    },
    // stepComponent(component) {
    //   return import(`./steps/${component.toLowerCase()};`);
    // },
    goNext() {
      //alert(this.$refs.wizard.activeTabIndex);
      this.$refs.wizard.nextTab();
    },
    onComplete() {
      if (!this.finished) {
        console.debug("done!");
        this.finished = true;
      } else {
        console.debug("already finished!");
      }
    },
    onFinish() {
      // TODO: $emit('finish') when lastStep completes
      this.finished = true;
    },
    validateStep(name, index) {
      // return this.$refs[name][0].validate();
      const Repo = RepositoryFactory.get(this.repository);
      // se uno step non ha un form, allora usa quello dallo Step1
      let payload =
        this.$refs[name][0].form && this.$refs[name][0].form[this.repository]
          ? this.$refs[name][0].form[this.repository]
          : this.$refs["Step1"][0].form[this.repository];
      // switch (name) {
      //   case "Step1":
      //     payload.status_procedure = 2;
      //     break;
      //   case "Step2":
      //     payload.status_procedure = 3;
      //     break;
      //   case "Step3":
      //     payload.status_procedure = 4;
      //     break;
      //   case "Step4":
      //     payload.status_procedure = 5;
      //     break;
      //   default:
      //     console.info(`Lo step ${name} va implementato.`);
      //     break;
      // }
      switch (index) {
        case 0:
          payload.status_procedure = 2;
          break;
        case 1:
          payload.status_procedure = 3;
          break;
        case 2:
          payload.status_procedure = 4;
          break;
        case 3:
          payload.status_procedure = 5;
          break;
        default:
          console.info(`Lo step ${name} va implementato.`);
          break;
      }
      if (name === this.tabs[this.tabs.length - 1].component) {
        if (this.finished) {
          this.$showSnackbar({
            preset: "info",
            text: `Procedura già completata`,
          });
          return true;
        }
      }
      this.isLoading = true;
      return Repo.update(this.resourceId, payload)
        .then(() => {
          if (index === 0 && this.extras.communication_type === 2) {
            const force_aggregate_payload = {
              unbundling: "Y",
            };
            return Repo.aggregate(this.resourceId, force_aggregate_payload)
              .then(() => {
                this.$showSnackbar({
                  preset: "success",
                  text: `Applicato con successo (${name})`,
                });
                this.$emit("fetch");
                // devo aggiornare status_procedure che tramite prop extras
                // viaggia fino al componente "CommunicationDetail" nel tab General
                this.$emit("status", payload.status_procedure);
                if (name === this.tabs[this.tabs.length - 1].component) {
                  if (this.finished) {
                    this.finished = true;
                  }
                }
                this.isLoading = false;
                // return this.$refs[name][0].validate();
                return true;
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: errMsg,
                });
                this.isLoading = false;
                return false;
              });
          } else {
            this.$showSnackbar({
              preset: "success",
              text: `Applicato con successo (${name})`,
            });
            this.$emit("fetch");
            // devo aggiornare status_procedure che tramite prop extras
            // viaggia fino al componente "CommunicationDetail" nel tab General
            this.$emit("status", payload.status_procedure);
            if (name === this.tabs[this.tabs.length - 1].component) {
              if (this.finished) {
                this.finished = true;
              }
            }
            this.isLoading = false;
            // return this.$refs[name][0].validate();
            return true;
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
          this.isLoading = false;
          return false;
        });
    },
    // mergeTabModel(model, isValid) {
    //   if (isValid) {
    //     // merging each step model into the final model
    //     this.tabModel = Object.assign({}, this.tabModel, model)
    //   }
    // }
  },
  computed: {
    getWidth() {
      return this.buttonWidth;
    },
    // stepComponent() {
    //   return (component) => import(`./steps/${component.toLowerCase()};`);
    // },
    tbs() {
      return this.tabs;
    },
    // actTbNdx() {
    //   return this.$refs.wizard.activeTabIndex;
    // },
  },
  beforeMount() {
    if (this.extras.status_procedure > this.tabs.length) {
      this.finished = true;
    }
  },
  mounted() {
    this.resize();
  },
};
</script>

<style lang="scss" scoped>
// :deep(.wizard-footer-left) {
//   cursor: not-allowed;
//   pointer-events: none;
//   display: none;
// }
// TODO: cambiare il colore del testo del wizard/
:deep(.wizard-title) {
  color: #6f6259;
}
:deep(.vue-form-wizard) {
  .wizard-tab-content {
    min-height: 100px;
    padding: 30px 0px 0px;
  }
}
:deep(.wizard-icon-circle .wizard-icon) {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.wizard-icon-circle .wizard-icon:after) {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  content: "";
}
:deep(.active .wizard-icon-circle .b-dash-circle:after) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' fill='%23fff'%3E%3C/path%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.wizard-icon-circle .b-dash-circle:after) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' fill='%23ccc'%3E%3C/path%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' fill='%23ccc'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.active .wizard-icon-circle .b-grid:after) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.wizard-icon-circle .b-grid:after) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z' fill='%23ccc'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.active .wizard-icon-circle .b-pencil-square:after) {
  width: 22px;
  height: 22px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' fill='%23fff'%3E%3C/path%3E%3Cpath d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.wizard-icon-circle .b-pencil-square:after) {
  width: 22px;
  height: 22px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' fill='%23ccc'%3E%3C/path%3E%3Cpath d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z' fill='%23ccc'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.active .wizard-icon-circle .b-file-earmark-medical:after) {
  width: 26px;
  height: 26px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z' fill='%23fff'%3E%3C/path%3E%3Cpath d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.wizard-icon-circle .b-file-earmark-medical:after) {
  width: 26px;
  height: 26px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z' fill='%23ccc'%3E%3C/path%3E%3Cpath d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z' fill='%23ccc'%3E%3C/path%3E%3C/svg%3E");
}
:deep(.vue-form-wizard .wizard-card-footer) {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 6;
  width: 0;
  padding: 0;
  // transition: all 0.2s linear;
}
:deep(.vue-form-wizard .wizard-footer-right) {
  float: none;
}
:deep(.vue-form-wizard .wizard-footer-right.hidden) {
  visibility: hidden !important;
  opacity: 0 !important;
  z-index: -1 !important;
  pointer-events: none !important;
  touch-action: none !important;
  cursor: default !important;
  user-select: none !important;
  -webkit-tap-highlight-color: transparent !important;
}
:deep(.vue-form-wizard button.wizard-footer-right) {
  transition: all 0s linear;
}
:deep(.vue-form-wizard div.wizard-footer-right) {
  float: none;
  padding: 10px 1rem;
  background: #f3f0ea;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
:deep(.wizard-navigation li a) {
  gap: 10px;
}
</style>
