<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-row>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  @click="handleSubmit(onSubmit('N'))"
                  type="button"
                  :disabled="invalid || isCommunicationTypeSMS"
                  variant="lisaweb"
                  size="sm"
                >
                  Aggrega
                </b-button>

                <b-button
                  @click="handleSubmit(onSubmit('Y'))"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                >
                  Disaggrega
                </b-button>
              </div>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
// import BaseSelect from "@/components/form/BaseSelect";
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
export default {
  data() {
    return {
      isLoading: false,
      repository: "communication_procedure",
      resource: "communication_procedures",
    };
  },
  props: {
    resourceId: Number,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    // BaseSelect,
  },
  methods: {
    onSubmit(unbundling) {
      // console.log(this.form[this.repository]);
      this.isLoading = true;
      let form = {
        unbundling,
      };
      const Repo = RepositoryFactory.get(this.repository);
      Repo.aggregate(this.resourceId, form)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Procedura Modificata`,
          });
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      // change `true` to things checked on model, beyond scope of question
      // this.$emit("on-validate", this.$data, true);
      // this.$emit("on-validate", true, this.index);
      // this.$emit("on-validate", this.allSelectsNotNull(), this.index);
      // return this.allSelectsNotNull();
      this.$emit("on-validate", true, this.index);
      return true;
    },
  },
  computed: {
    isCommunicationTypeSMS() {
      /* Per la casistica SMS l'elenco si deve trasformare in una scadenza singola
       * in capo al destinatario; bisogna disabilitare il pulsante 'AGGREGA',
       * in modo che l'utente non possa riaggregare.
       * Così facendo ci si presenta all'invio con tanti SMS quante sono le scadenze,
       * quindi, il cliente che ne aveva 3, riceve 3 SMS.
       * All'atterraggio allo Step2, il back-end deve effettuare una disgregazione di tutto,
       * mentre, lato FE, blocchiamo semplicemente la possibilità di aggregare.
       */
      const ct =
        this.communication_type ?? this.$route.params.communication_type;
      return ct === 2 ? true : false;
    },
    rep() {
      return this.repository;
    },
  },
  beforeMount() {
    //   this.isLoading = true;
    //   this.fetchEditForm(this.repository, parseInt(this.resourceId))
    //     .then(() => {
    //       this.setDefaultValues();
    //       console.log("BeForm: ", this.beForm);
    //       console.log("form: ", this.form[this.repository]);
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: errMsg,
    //       });
    //     });
  },
};
</script>

<style></style>
