<template>
  <div class="mt-2 pb-5">
    <div class="table-wrapper">
      <b-table
        :busy="isLoading"
        head-variant="light"
        :no-local-sorting="true"
        :items="items"
        :fields="getAllFields"
        :current-page="currentPage"
        per-page="0"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :sticky-header="height"
        no-border-collapse
        responsive
        show-empty
        empty-text="Non ci sono informazioni da mostrare"
        small
        @sort-changed="sortingChanged"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <base-icon name="loading" width="35" height="35" />
            <p>Operazione in corso...</p>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="action-buttons">
            <b-button
              v-if="actions.includes('infomodal')"
              size="sm"
              variant="lisaweb"
              @click="openModal(row.item, row.index, $event.target)"
              class="mt-1 mr-1"
              title="Modale Dettagli"
            >
              <b-icon icon="eye"></b-icon>
            </b-button>

            <b-button
              v-if="actions.includes('details')"
              size="sm"
              variant="lisaweb"
              @click="row.toggleDetails"
              class="mt-1 mr-1"
              :title="row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'"
            >
              <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
              <b-icon v-else icon="chevron-down"></b-icon>
            </b-button>

            <!-- <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button> -->

            <b-button
              v-if="actions.includes('edit')"
              size="sm"
              variant="lisaweb"
              @click="onEdit(row.item.id, row.item)"
              class="mt-1 mr-1"
              title="Riprendi"
              :disabled="row.item.status_procedure.value == 5"
            >
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
            <b-button
              v-if="actions.includes('destroy')"
              size="sm"
              variant="lisaweb"
              @click="onDestroy(row.item.id)"
              class="mt-1 mr-1"
              title="Elimina"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
        </template>
        <template #cell(registry)="{ item }">
          {{ toInfoData(item.registry, "registryfullname", null) }}
        </template>
        <template #cell(address)="{ item }">
          <!-- {{ toInfoData(item.registry, "address", null, null) }} -->
          <span v-html="toInfoData(item.registry, 'address', 0)"></span>
        </template>

        <template #cell(address_books)="{ item }">
          <span
            v-html="toInfoData(item.registry, 'address_books', null)"
          ></span>
        </template>

        <!-- Le colonne sono
          ◦ Data: Data del Q relazionato nella riga, se non c’è id q, data dal json dell’inserimento manuale
          ◦ Polizza: Numero polizza del Q relazionato nella riga, se non c’è id q, vuoto
          ◦ Descrizione: In base al tipo di q:
              ▪ Sospeso: mostra il testo “sospeso”
              ▪ Acconto: mostra il testo “acconto”
              ▪ Da incassare: mostra il prodotto della polizza del q relazionato
              ▪ Arretrato: mostra il testo “arretrato”
              ▪ Non c’è id di Q: descrizione dal json dell’inserimento manuale
          ◦ Importo: mostra sempre il lordo del Q relazionato con la riga, se non c’è Q mostra il valore dal json dell’inserimento manuale -->
        <template #cell(details)="{ item }">
          <!-- <table class="table">
              <thead>
                <th role="columnheader">Data</th>
                <th role="columnheader">Polizza</th>
                <th role="columnheader">Descrizione</th>
                <th role="columnheader">Importo</th>
                <th role="columnheader">Azioni</th>
              </thead>
              <tbody>
                <tr
                  v-for="(detail, index) in item.communication_details"
                  :key="index"
                >
                  <td>
                    {{ toLocaleDate(toReferringAt(detail)) }}
                  </td>
                  <td>
                    {{ toPolicy(detail) }}
                  </td>
                  <td>
                    {{ toDescription(detail) }}
                  </td>
                  <td>
                    {{ toLocaleCurrency(toGross(detail)) }}
                  </td>
                  <td>
                    <b-button
                      v-if="canVerb('communication_details', 'destroy')"
                      size="sm"
                      variant="lisaweb"
                      @click="onDestroyDetail(detail.id)"
                      class="mt-1 mr-1"
                      title="Elimina"
                      ><b-icon icon="trash"></b-icon>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table> -->
          <span v-html="toTable(item)"></span>
        </template>
        <template #cell(note)="{ item }">
          <span style="white-space: pre-wrap; word-break: break-word">{{
            item.note
          }}</span>
        </template>
        <!-- Somma di tutti i lordi dei q relazionati nelle righe del raggruppamento. 
          Se una o più righe sono manuali, usare il valore nel json manuale. 
          Importante: per i Q di tipo acconto non va sommato ma sottratto! -->
        <template #cell(total)="{ item }">
          {{ toLocaleCurrency(toGrossPartial(item)) }}
        </template>
      </b-table>
    </div>
    <b-row>
      <div class="show-text">
        <p>Mostra</p>
      </div>

      <div class="group">
        <b-form-group
          label-for="per-page-select"
          label-cols-sm="3"
          label-cols-md=""
          label-cols-lg="3"
          label-size="sm"
          md="auto"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            style="width: 68px"
            size="sm"
            @change="onPageChange(1)"
            :disabled="!items.length"
          ></b-form-select>
        </b-form-group>
      </div>

      <div class="page">
        <p>di {{ totalItems }} per pagina</p>
      </div>
      <div class="pagination" v-if="totalItems">
        <b-pagination
          v-model="currentPage"
          limit="3"
          :total-rows="totalItems"
          :per-page="perPage"
          size="sm"
          @change="onPageChange"
        ></b-pagination>
      </div>
    </b-row>
    <b-modal
      :id="infomodalName"
      @hidden="onModalHidden"
      :title="modalTitle"
      size="xl"
      scrollable
      ok-only
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      no-close-on-backdrop
    >
      <div>
        <b-tabs content-class="pt-1" lazy v-model="tabIndex">
          <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            @click="setTab(tab, index)"
            :title="tab.text"
            :active="index === tabIndex"
            title-item-class="lisaweb"
          >
            <keep-alive>
              <component
                v-if="selected === tab.name"
                :is="selected"
                :resourceId="modalResourceId"
                @fetch="fetch"
                @loadIndex="$emit('loadIndex')"
                :extras="{ status_procedure: sts }"
              ></component>
            </keep-alive>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import General from "@/components/communication/groupings/details/General";
// import CommunicationDetail from "@/components/communication/groupings/details/CommunicationDetail";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
export default {
  name: "CommunicationGroupingsTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Generale" },
        // { name: "CommunicationDetail", text: "Righe" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
      sp: null,
    };
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    toTable(item) {
      let rows = [];
      item.communication_details.forEach((detail) => {
        const shown_discarded_icon = `<svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 236.46 237"
                width="20"
              >
                <title>Premio escluso da filtro</title>
                <path
                  d="M85.41 158.54c-1.75 0-3.4-.69-4.64-1.94-2.63-2.64-2.64-6.94-.03-9.6l28.11-28.52-28.14-28.54c-2.56-2.6-2.5-6.84.12-9.46 1.26-1.25 2.92-1.95 4.67-1.95a6.37 6.37 0 0 1 4.59 1.92l28.13 28.52 28.16-28.56c1.25-1.27 2.91-1.97 4.67-1.97a6.48 6.48 0 0 1 4.64 1.94c2.63 2.64 2.64 6.94.03 9.6l-28.11 28.51 28.14 28.54c2.56 2.6 2.51 6.84-.12 9.46-1.26 1.25-2.92 1.95-4.67 1.95a6.37 6.37 0 0 1-4.59-1.92L118.24 128l-28.16 28.56c-1.25 1.27-2.91 1.97-4.67 1.97h0zM118.23 0c32.73 0 62.35 13.26 83.79 34.71 21.44 21.44 34.71 51.07 34.71 83.79s-13.26 62.35-34.71 83.79C180.58 223.73 150.95 237 118.23 237s-62.35-13.26-83.79-34.71C12.99 180.85-.27 151.22-.27 118.5s13.26-62.35 34.71-83.79C55.88 13.26 85.51 0 118.23 0h0zm74.62 43.88c-19.1-19.1-45.48-30.91-74.62-30.91S62.71 24.78 43.61 43.88 12.7 89.36 12.7 118.5s11.81 55.52 30.91 74.62 45.48 30.91 74.62 30.91 55.52-11.81 74.62-30.91 30.91-45.48 30.91-74.62-11.81-55.52-30.91-74.62h0z"
                  fill="#ff0000"
                />
              </svg>`;
        const broker_code = detail?.broker?.attributables?.BUID ?? "";
        const broker_name = detail?.broker?.formatted_title ?? "";
        const broker = `${broker_code}${
          broker_code && broker_name ? " - " : ""
        }${broker_name}`;
        console.log("broker", broker.length);
        let columns = [
          `<td>${
            detail.is_shown_discarded.value === "Y" ? shown_discarded_icon : ""
          }</td>`,
          `<td>${this.toLocaleDate(this.toReferringAt(detail))}</td>`,
          `<td>${this.toPolicy(detail)}</td>`,
          `<td>${this.toDescription(detail)}</td>`,
          `<td>${detail?.registry?.formatted_title ?? "-"}</td>`,
          `<td>${broker && broker.length ? broker : "-"}</td>`,
          `<td class="text-right">${this.toLocaleCurrency(
            this.toGross(detail)
          )}</td>`,
        ];
        let row = `<tr>${columns.join("")}</tr>`;
        rows.push(row);
      });
      let ret = `
      <table class="table">
        <thead>
          <th role="columnheader"></th>
          <th role="columnheader">Data</th>
          <th role="columnheader">Polizza</th>
          <th role="columnheader">Descrizione</th>
          <th role="columnheader">Contraente</th>
          <th role="columnheader">Cod. Produttore</th>
          <th role="columnheader" class="text-right">Importo</th>
        </thead>
        <tbody>
        ${rows.join("")}
        </tbody>
      </table>
      `;
      return ret;
    },
    toPolicy(item) {
      let ret = "-";
      if (!item.insurance_payment) {
        console.log(`item.id ${item.id} has not insurance_payment`);
        return ret;
      }
      let pt = item.insurance_payment.payment_type.value;
      try {
        switch (pt) {
          case 0:
          case 3:
            ret =
              item.insurance_payment.book_entry.various_accountings[0].pivot
                .insurance_policy_number;
            break;
          default:
            ret =
              item.insurance_payment.insurance_ancillary.insurance_policy
                .number;
        }
      } catch (error) {
        console.error(error);
      }
      return ret;
    },
    toReferringAt(item) {
      return item.insurance_payment_id && item.insurance_payment
        ? item.insurance_payment.referring_at
        : item.custom_data
        ? item.custom_data.date
        : "not found";
    },
    toDescription(item) {
      /* ◦ Descrizione: In base al tipo di q:
        ▪ Sospeso: mostra il testo “sospeso”
        ▪ Acconto: mostra il testo “acconto”
        ▪ Da incassare: mostra il prodotto della polizza del q relazionato
        ▪ Arretrato: mostra il testo “arretrato”
        ▪ Non c’è id di Q: descrizione dal json dell’inserimento manuale

        "0":"Sospeso",
        "1":"Arretrato",
        "2":"Quietanza",
        "3":"Acconto",
        "4":"Manuale"
      */
      let ret = "";
      if (!item.insurance_payment) {
        return item.custom_data ? item.custom_data.description : "not found";
      }
      let pt = item.insurance_payment.payment_type.value;
      try {
        switch (pt) {
          case 0:
            ret = "sospeso";
            break;
          case 1:
            ret = "arretrato";
            break;
          case 2:
          case 4:
            // Da incassare
            // prodotto: item.insurance_payment.book_entry.various_accountings[0].pivot.insurance_risk.formatted_title
            //          tem.insurance_payment.insurance_ancillary.insurance_policy.insurance_risk.formatted_title
            ret = `polizza ${item.insurance_payment.insurance_ancillary.insurance_policy.number} ${item.insurance_payment.insurance_ancillary.insurance_policy.insurance_risk.formatted_title}`;
            break;
          case 3:
            ret = "acconto";
            break;

          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
      return ret;
    },
    toGross(item) {
      // Importo: mostra sempre il lordo del Q relazionato con la riga, se non c’è Q mostra il valore dal json dell’inserimento manuale
      let ret = "";
      if (!item.insurance_payment) {
        ret = item.custom_data ? item.custom_data.gross : "not found";
      } else {
        ret = item.insurance_payment.gross;
      }
      return ret;
    },
    toGrossPartial(item) {
      let initialValue = 0;
      let sum = item.communication_details.reduce(
        (previousValue, currentValue) => {
          console.log(currentValue);
          return (
            previousValue +
            // (currentValue.insurance_payment
            //   ? currentValue.insurance_payment.gross
            //   : 0)
            // this.toGross(currentValue)
            // se acconto, *-1
            this.toGross(currentValue)
            // commentanto in quanto nel quietanziamento gli acconti hanno già segno negativo
            // this.toGross(currentValue) *
            //   (currentValue.insurance_payment &&
            //   currentValue.insurance_payment.payment_type.value === 3
            //     ? -1
            //     : 1)
          );
        },
        initialValue
      );
      return sum;
    },
  },
  components: {
    BaseIcon,
    General,
    // CommunicationDetail,
  },
  props: {
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    sts() {
      return this.sp != null ? this.sp : this.extras.status_procedure;
    },
  },
};
</script>

<style lang="scss" scoped>
// :deep(th[role="columnheader"]) {
//   color: #6f6259 !important;
// }
:deep(.table) {
  th {
    border-bottom: 0px solid #dee2e6;
    &[role="columnheader"] {
      color: #6f6259 !important;
    }
  }
}
:deep(.table) {
  td {
    vertical-align: middle;
  }
}
</style>
