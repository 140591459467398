<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <!-- 
              tendina "Escludi per canale”: S’/No, default No
              tendina “Escludi per produttore”: Sì/No, default No
              tendina “Mostra premi auto”: Sì/No/Solo frazionamento, default Sì
              tendina “Mostra premi vita”: Sì/No/Solo frazionamento, default Sì
              tendina “Mostra premi extra auto”: Sì/No/Solo frazionamento, default Sì -->
            <b-row>
              <div class="col-md-3">
                <base-select
                  vid="is_channel_excluded"
                  :name="beForm[rep].is_channel_excluded.label"
                  label="Escludi per canale preferito"
                  v-model="form[rep].is_channel_excluded"
                  :options="beForm[rep].is_channel_excluded.options"
                  :rules="getRules('is_channel_excluded')"
                  :allowEmpty="false"
                />
                <!-- :label="getDictionary('is_channel_excluded', rep)" -->
                <!-- :rules="getRules('is_channel_excluded')" -->
                <small style="display: block; margin: -0.25rem 0 1.25rem"
                  >Esclusione clienti con canale preferenziale differente da
                  quello scelto: {{ getCommunicationTypeText }}</small
                >
              </div>
              <div class="col-md-3">
                <base-select
                  vid="is_broker_excluded"
                  :name="beForm[rep].is_broker_excluded.label"
                  label="Escludi per produttore"
                  v-model="form[rep].is_broker_excluded"
                  :options="beForm[rep].is_broker_excluded.options"
                  :rules="getRules('is_broker_excluded')"
                  :allowEmpty="false"
                />
                <small style="display: block; margin: -0.25rem 0 1.25rem"
                  >Esclusione righe di polizza per Produttore avente attivo
                  l'esclusione di invio scadenze</small
                >
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-select
                  vid="is_vehicle_premium_shown"
                  :name="beForm[rep].is_vehicle_premium_shown.label"
                  label="Mostra Premi Danni Auto nell'avviso di scadenza"
                  v-model="form[rep].is_vehicle_premium_shown"
                  :options="beForm[rep].is_vehicle_premium_shown.options"
                  :rules="getRules('is_vehicle_premium_shown')"
                  :allowEmpty="false"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  vid="is_life_premium_shown"
                  :name="beForm[rep].is_life_premium_shown.label"
                  label="Mostra Premi Vita nell'avviso di scadenza"
                  v-model="form[rep].is_life_premium_shown"
                  :options="beForm[rep].is_life_premium_shown.options"
                  :rules="getRules('is_life_premium_shown')"
                  :allowEmpty="false"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  vid="is_extra_vehicle_premium_shown"
                  :name="beForm[rep].is_extra_vehicle_premium_shown.label"
                  label="Mostra Premi Danni Extra Auto nell'avviso di scadenza"
                  v-model="form[rep].is_extra_vehicle_premium_shown"
                  :options="beForm[rep].is_extra_vehicle_premium_shown.options"
                  :rules="getRules('is_extra_vehicle_premium_shown')"
                  :allowEmpty="false"
                />
              </div>
            </b-row>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Applica
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import FormMixin from "@/mixins/FormMixin";
export default {
  mixins: [FormMixin],
  data() {
    return {
      isLoading: false,
      repository: "communication_procedure",
      resource: "communication_procedures",
      form: {
        communication_procedure: {},
      },
      defaultValues: {
        is_channel_excluded: "N",
        is_broker_excluded: "N",
        is_vehicle_premium_shown: 1,
        is_life_premium_shown: 1,
        is_extra_vehicle_premium_shown: 1,
      },
    };
  },
  props: {
    resourceId: Number,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    BaseSelect,
  },
  methods: {
    setDefaultValues() {
      for (const [field, value] of Object.entries(this.defaultValues)) {
        if (this.form[this.repository][field] == null) {
          this.form[this.repository][field] = value;
        }
      }
    },
    allSelectsNotNull() {
      let ret = Object.keys(this.defaultValues).every(
        (e) => this.form[this.repository][e] != null
      );
      return ret;
    },
    onSubmit() {
      // console.log(this.form[this.repository]);
      this.isLoading = true;
      this.update(this.repository, this.resourceId)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Procedura Modificata`,
          });
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      // change `true` to things checked on model, beyond scope of question
      // this.$emit("on-validate", this.$data, true);
      // this.$emit("on-validate", true, this.index);
      // this.$emit("on-validate", this.allSelectsNotNull(), this.index);
      // return this.allSelectsNotNull();
      this.$emit("on-validate", true, this.index);
      return true;
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
    getCommunicationTypeText() {
      let text = "";
      switch (this.extras.communication_type) {
        case 0:
          text = "Email";
          break;
        case 2:
          text = "SMS";
          break;
        case 3:
          text = "Posta";
          break;
        default:
          text = "";
          break;
      }
      return text;
    },
  },
  beforeMount() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, parseInt(this.resourceId))
      .then(() => {
        this.setDefaultValues();
        // console.log("BeForm: ", this.beForm);
        // console.log("form: ", this.form[this.repository]);
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: errMsg,
        });
      });
  },
};
</script>

<style></style>
